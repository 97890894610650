
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'

@Component
export default class PlanDetail extends Vue {
  private info = {
    status: '',
    planType: ''
  }

  private rejectInfo: { reject: string } = {
    reject: ''
  }

  private loading = false
  private submitShow = false
  private rejectShow = false

  get reportId () {
    return this.$route.params.reportId || ''
  }

  get approve () {
    return this.$route.params.approve || ''
  }

  private rules = {
    reject: [
      { required: true, message: '请输入驳回说明', trigger: 'change' }
    ]
  }

  created () {
    this.reportId && this.getDetail()
  }

  getDetail () {
    this.loading = true
    this.$axios.get(this.$apis.operationmanage.selectYhPlanReportByReportId, { reportId: this.reportId }).then((res) => {
      this.info = res || {}
    }).finally(() => {
      this.loading = false
      this.getTitle()
    })
  }

  getTitle () {
    const titleList = ['周工作计划详情', '周工作汇报详情', '月工作计划详情']
    if (this.$route.meta) {
      if (this.info.status === '2' && this.approve === '1') {
        this.$route.meta.title = '审核'
      } else {
        this.$route.meta.title = titleList[Number(this.info.planType) - 1]
      }
    }
    this.$router.replace({
      query: {
        temp: Date.now().toString()
      }
    })
  }

  // 提交
  onSubmit () {
    this.submitShow = true
    this.info.status = '2'
    this.$axios.post(this.$apis.operationmanage.updateYhPlanReport, {
      ...this.info
    }).then(() => {
      this.$message.success('提交成功')
      this.$router.back()
    }).finally(() => {
      this.submitShow = false
    })
  }

  // 批准
  onSubmitApprove () {
    this.$confirm('确认通过审核？', '提示').then(() => {
      this.submitShow = true
      this.$axios.post(this.$apis.operationmanage.updateStatusWithdraw, {
        reportId: this.reportId,
        status: '3'
      }).then(() => {
        this.$message.success('审核成功')
        this.$router.back()
      }).finally(() => {
        this.submitShow = false
      })
    })
  }

  // 驳回
  onSubmitReject () {
    (this.$refs.rejectInfo as ElForm).validate(valid => {
      if (valid) {
        this.submitShow = true
        this.$axios.post(this.$apis.operationmanage.updateStatusWithdraw, {
          reportId: this.reportId,
          status: '4',
          reject: this.rejectInfo.reject
        }).then(() => {
          this.$message.success('审核成功')
          this.$router.back()
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }

  // 关闭
  closeDialog () {
    this.rejectShow = false
    this.rejectInfo = {
      reject: ''
    }
    ;(this.$refs.rejectInfo as ElForm).clearValidate()
  }
}
